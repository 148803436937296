import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import type { CheckboxProps } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Loading } from '@app/components/common/Loading';
import { Image } from 'antd';
import LogoImage from '@app/assets/images/SH_Logo_CR_PNG.png';
import * as S from './VerifyMarketplaceAccountForm.styles';
import * as L from '@app/components/auth/LoginForm/LoginForm.styles';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifyMarketplaceAccount } from '@app/store/slices/authSlice';

interface VerifyMarketplaceAccountFormData {
  email: string;
}

const initValues = {
  email: '',
  rememberMe: true,
};

export const VerifyMarketplaceAccountForm: React.FC = () => {
  const skipValidation = Cookies.get('skipValidation') == "true";
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState<boolean>(!skipValidation);
  const [checkedRememberMe, setCheckedRememberMe] = useState<boolean>(true);
  const [showRedirectMsgBox, setShowRedirectMsgBox] = useState<boolean>(skipValidation);
  const [showValidatedMsgBox, setShowValidatedMsgBox] = useState<boolean>(false);
  const [showActivationMsgBox, setShowActivationMsgBox] = useState<boolean>(false);
  const [showRegistrationMsgBox, setShowRegistrationMsgBox] = useState<boolean>(false);
  const [showBillToOthersMsgBox, setShowBillToOthersMsgBox] = useState<boolean>(false);

  const onChangeeRememberMe: CheckboxProps['onChange'] = (e) => {
    setCheckedRememberMe(e.target.checked);
  };

  const handleSubmit = (values: VerifyMarketplaceAccountFormData) => {
    setLoading(true);
    dispatch(doVerifyMarketplaceAccount(values))
      .unwrap()
      .then((res) => {
        setShowForm(false);
        if (res == "Validated")
          setShowValidatedMsgBox(true);
        else if (res == "BillToOthers")
          setShowBillToOthersMsgBox(true);
        else
          setShowActivationMsgBox(true);
      })
      .catch((err) => {
        setShowForm(false);
        setShowRegistrationMsgBox(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderEmailForm = () => {
    return (
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.Description>Please enter your registered billing contact email to enter MyHUB Billing Center.</S.Description>
        <Auth.FormItem
          name="email"
          rules={[{ required: true }]}
        >
          <Auth.FormInput placeholder={t('common.email')} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Go to Portal
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    )
  }

  const renderRedirectMsg = () => {
    setTimeout(() => {
      window.location.href = "https://store.superhub.com.hk/my-account/?mode=account-statement";
    }, 3000)

    return (
      <BaseForm layout="vertical" onFinish={() => { setShowRedirectMsgBox(false); setShowForm(true); }} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.Description>Just a moment, we are navigating you to the portal.</S.Description>
        <BaseForm.Item noStyle>
          <Loading />
        </BaseForm.Item>
      </BaseForm>
    )
  }

  const renderValidatedMsg = () => {
    const onFinish = () => {
      if (checkedRememberMe)
        Cookies.set('skipValidation', 'true', { expires: 3650 });
      window.location.href = "https://store.superhub.com.hk/my-account/?mode=account-statement";
    }

    return (
      <BaseForm layout="vertical" onFinish={onFinish} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.SubTitle>Successful Login ID Security Validation</S.SubTitle>
        <S.Description>Please press <b>OK</b> to direct to <S.CompanyName>SUPERHUB</S.CompanyName> MyHUB - Billing Center.</S.Description>
        <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
          <Auth.FormCheckbox checked={checkedRememberMe} onChange={onChangeeRememberMe}>
            <S.RememberMeText>{t('login.rememberMe')}</S.RememberMeText>
          </Auth.FormCheckbox>
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            OK
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    )
  }

  const renderActivationMsg = () => {
    return (
      <BaseForm layout="vertical" onFinish={() => { setShowActivationMsgBox(false); setShowForm(true); }} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.SubTitle>Your email Login ID requires to setup a new password</S.SubTitle>
        <S.Description>We have sent you an activation email just now. To log in to <S.CompanyName>SUPERHUB</S.CompanyName> MyHUB - Billing Center, please check your email box to setup and confirm password.</S.Description>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            OK
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    )
  }

  const renderRegistrationMsg = () => {
    return (
      <BaseForm layout="vertical" onFinish={() => { setShowRegistrationMsgBox(false); setShowForm(true); }} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.SubTitle>Your email address is not registered yet</S.SubTitle>
        <S.Description>To access <S.CompanyName>SUPERHUB</S.CompanyName> MyHUB - Billing Center, we recommend you to contact your company's administrator to submit request to <S.CompanyName>SUPERHUB</S.CompanyName> for granting you additional right. Thank you for your attention.</S.Description>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            OK
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    )
  }

  const renderBillToOthersMsg = () => {
    return (
      <BaseForm layout="vertical" onFinish={() => { setShowBillToOthersMsgBox(false); setShowForm(true); }} requiredMark="optional" initialValues={initValues}>
        <L.ImageWrapper>
            <Image src={LogoImage} alt="SUPERHUB" preview={false} />
        </L.ImageWrapper>
        <Auth.FormTitle>MyHUB - Billing Center</Auth.FormTitle>
        <S.SubTitle>Your invoices are billed to another account.</S.SubTitle>
        <S.Description>Please enter the registered billing contact email of that account to enter MyHUB Billing Center.</S.Description>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            OK
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    )
  }

  return (
    <>
      <Auth.FormWrapper>
        {showForm && renderEmailForm()}
        {showRedirectMsgBox && renderRedirectMsg()}
        {showValidatedMsgBox && renderValidatedMsg()}
        {showActivationMsgBox && renderActivationMsg()}
        {showRegistrationMsgBox && renderRegistrationMsg()}
        {showBillToOthersMsgBox && renderBillToOthersMsg()}
      </Auth.FormWrapper>
    </>
  );
};
