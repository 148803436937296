import { Col, Row, Tag } from 'antd';
import { NotificationType } from '@app/components/common/Notification/Notification';
import { Priority } from '@app//constants/enums/priorities';
import { ReactComponent as ETHIcon } from '@app/assets/icons/eth.svg';
import { ReactComponent as BTCIcon } from '@app/assets/icons/btc.svg';

// import visa from '@app/assets/images/card-issuers/visa.png';
// import mastercard from '@app/assets/images/card-issuers/mastercard.png';
// import maestro from '@app/assets/images/card-issuers/maestro.png';
import { 
  CurrencyTypeEnum, 
  StringIdDropdownItem, 
} from '@app/interfaces/interfaces';
import moment from 'moment';
import { DateConverter } from '@app/components/nft-dashboard/admin/TokenCenter/Customers/ConversionHelper';
import { SystemMessageType } from '@app/enums/SystemMessageType';
import { AccountManageType } from '@app/enums/AccountManageType';
import { RenewalCustomer, SystemMessage } from '@app/interfaces/renewals';

export const camelize = (string: string): string => {
  return string
    .split(' ')
    .map((word, index) => (index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)))
    .join('');
};

export const checkInclude = (value: string, pattern:string) =>{
  return value?.toLowerCase().includes(pattern.toLowerCase());
};

export const removeWhiteSpace = (value: string)=>{
  return value.trim().replace(/\s/g, '').toLowerCase();
};

export const getUnique = (value: any, index: any, self: any) => {
  return self.indexOf(value) === index;
};

export const getObjectValue = (object:any, key:string, placeholder:string) =>{
  return object == null? placeholder
  : object[`${key}`] == null? placeholder 
  : object[`${key}`];
};

export const isNumber = (val: any) => 'number' === typeof val;

export const isBoolean = (val: any) => 'boolean' === typeof val;

export const formatBoolean = (val: boolean) => val ? 'Yes' : 'No';

export const isNullOrEmptyString = (val: any) => val === "" || val === null;

export const showValueOrPlaceholder = (value:any)=>{
  const placeholder = "--";
  return value? value: placeholder;
}

export const getCurrencyPrice = (
  price: number | string,
  currency: CurrencyTypeEnum,
  isIcon = true,
): string | React.ReactNode => {
  switch (CurrencyTypeEnum[currency]) {
    case 'USD': {
      return isIcon ? `$${price}` : `${price} USD`;
    }

    case 'BTC': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <BTCIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} BTC`
      );
    }

    case 'ETH': {
      return isIcon ? (
        <Row align="middle" gutter={[8, 8]}>
          <Col style={{ display: 'flex' }}>
            <ETHIcon />
          </Col>

          <Col>{price}</Col>
        </Row>
      ) : (
        `${price} ETH`
      );
    }

    default: {
      return isIcon ? `$${price}` : `${price} USD`;
    }
  }
};

type MarkArea = {
  xAxis: number;
};

export const getMarkAreaData = (data: string[] | number[]): MarkArea[][] =>
  data.map((el, index) => [
    {
      xAxis: 2 * index,
    },
    {
      xAxis: 2 * index + 1,
    },
  ]);

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const hexToRGB = (hex: string): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return `${r}, ${g}, ${b}`;
};

export const getDifference = (value: number, prevValue: number): string | null =>
  prevValue !== 0 ? `${((Math.abs(value - prevValue) / prevValue) * 100).toFixed(0)}%` : '100%';

export const normalizeProp = (prop: string | number | [number, number]): string =>
  typeof prop === 'number' ? `${prop}px` : (Array.isArray(prop) && `${prop[0]}px ${prop[1]}px`) || prop.toString();

export const defineColorByPriority = (priority: Priority): string => {
  switch (priority) {
    case Priority.INFO:
      return 'var(--primary-color)';
    case Priority.LOW:
      return 'var(--success-color)';
    case Priority.MEDIUM:
      return 'var(--warning-color)';
    case Priority.HIGH:
      return 'var(--error-color)';
    default:
      return 'var(--success-color)';
  }
};

export const defineColorBySeverity = (severity: NotificationType | undefined, rgb = false): string => {
  const postfix = rgb ? 'rgb-color' : 'color';
  switch (severity) {
    case 'error':
    case 'warning':
    case 'success':
      return `var(--${severity}-${postfix})`;
    case 'info':
    default:
      return `var(--primary-${postfix})`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeBy = (a: any[], b: any[], key: string): any[] =>
  a.filter((elem) => !b.find((subElem) => subElem[key] === elem[key])).concat(b);

export const getSmoothRandom = (factor: number, start: number): number => {
  const halfEnvelope = 1 / factor / 2;
  const max = Math.min(1, start + halfEnvelope);
  const min = Math.max(0, start - halfEnvelope);

  return Math.random() * (max - min) + min;
};

export const shadeColor = (color: string, percent: number): string => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt(((R * (100 + percent)) / 100).toString());
  G = parseInt(((G * (100 + percent)) / 100).toString());
  B = parseInt(((B * (100 + percent)) / 100).toString());

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};

export const hexToHSL = (hex: string): { h: number; s: number; l: number } => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    (r /= 255), (g /= 255), (b /= 255);
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h, s;
    const l = (max + min) / 2;
    if (max == min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
          h = 0;
      }
      h /= 6;
    }
    return {
      h,
      s,
      l,
    };
  } else {
    throw new Error('Non valid HEX color');
  }
};

export const formatNumberWithCommas = (value: number): string => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const msToH = (ms: number): number => Math.floor(ms / 3600000);

export const hToMS = (h: number): number => h * 3600000;

// export const getPaymentCardTypeIcon = (type: string): string | null => {
//   switch (type) {
//     case 'visa':
//       return visa;
//     case 'mastercard':
//       return mastercard;
//     case 'maestro':
//       return maestro;
//     case 'amex':
//       return 'amex';
//     case 'discover':
//       return 'discover';
//     case 'diners':
//       return 'diners';
//     case 'jcb':
//       return 'jcb';
//     case 'unionpay':
//       return 'unionpay';
//     default:
//       return null;
//   }
// };

const extractValueInsideParentheses = (value?:string | null): string => {
  // Get value inside of the parentheses using regex
  const match = value?.match(/\(([^)]+)\)/);
  return match ? match[1] : "-";
}

const getCycleAndFrequencyCode = (subscriptionCycles: StringIdDropdownItem[], billingFrequencies: StringIdDropdownItem[],
                                  subscriptionCycle?:string | null, billingFrequency?:string | null): string => 
{
  let subscriptionCycleCode = extractValueInsideParentheses(subscriptionCycles.find(data => data.id == subscriptionCycle)?.name?.toString());
  let billingFrequencyCode = extractValueInsideParentheses(billingFrequencies.find(data => data.id == billingFrequency)?.name?.toString());
  return `${subscriptionCycleCode}${billingFrequencyCode}`;
}

export const getAdvancePaymentAndDepositPolicyByCustomer = (customer:RenewalCustomer): SystemMessage | null  => 
{
  let message: SystemMessage | null = null;

  if (customer.isRisky == true)
  {
    message = {
      type: SystemMessageType.Warning,
      message: <>
        <span>Renewal of <b>Risky</b> Account</span><br/>
        <br/>
        <span>For <Tag>MS License (with / without Plus)</Tag> <Tag>Azure RI/SS/SP/Windows Server</Tag>:</span><br/>
        <span>- Written confirmation<sup>1</sup> with advance payment required</span><br/>
        <span>- Only YY allowed, no YM/MM</span><br/>
        <br/>
        <span>For <Tag>any 3-party Products</Tag>:</span><br/>
        <span>- Back-to-back offer with advance payment required</span><br/>
        <br/>
        <span>For <Tag>Standalone Modern+ / Safeti+</Tag> <Tag>MIT Plan</Tag> <Tag>Azure PAYG</Tag> <Tag>Manage Plus</Tag> <Tag>PAYG Hosted Product</Tag>:</span><br/>
        <span>- Advance payment &/or Deposit is not applicable</span><br/>
      </>,
    }
  }
  else if (customer.accountManageType == AccountManageType.Soho)
  {
    message = {
      type: SystemMessageType.Warning,
      message: <>
        <span>Renewal of <b>SOHO</b> Account</span><br/>
        <br/>
        <span>For <Tag>MS License (with / without Plus)</Tag> <Tag>Azure RI/SS/SP/Windows Server</Tag>:</span><br/>
        <span>- Written confirmation<sup>1</sup> with advance payment required</span><br/>
        <span>- Cannot change from YY to YM/MM (can keep YM/MM if previous contract has been approved)</span><br/>
        <span>- To keep YM, charge extra deposit required (if applicable)</span><br/>
        <span>- To keep MM, charge extra deposit / advance payment for whole service period<sup>2</sup> required required (if applicable)</span><br/>
        <br/>
        <span>For <Tag>any 3-party Products</Tag>:</span><br/>
        <span>- Back-to-back offer with advance payment required</span><br/>
        <br/>
        <span>For <Tag>Standalone Modern+ / Safeti+</Tag> <Tag>MIT Plan</Tag> <Tag>Azure PAYG</Tag> <Tag>Manage Plus</Tag> <Tag>PAYG Hosted Product</Tag>:</span><br/>
        <span>- Advance payment &/or Deposit is not applicable</span><br/>
      </>,
    }
  }
  else if (customer.accountManageType == AccountManageType.Managed)
  {
    message = {
      type: SystemMessageType.Warning,
      message: <>
        <span>Renewal of <b>Managed</b> Account</span><br/>
        <br/>
        <span>For <Tag>MS License (with / without Plus)</Tag> <Tag>Azure RI/SS/SP/Windows Server</Tag>:</span><br/>
        <span>- Written confirmation<sup>1</sup> with <b>no</b> advance payment required</span><br/>
        <span>- If change from YY to YM, 2-month deposit required</span><br/>
        <span>- If change from YY to MM, 2-month deposit / advance payment for whole service period<sup>2</sup> required</span><br/>
        <span>- To keep YM, charge extra deposit required (if applicable)</span><br/>
        <span>- To keep MM, charge extra deposit / advance payment for whole service period<sup>2</sup> required (if applicable)</span><br/>
        <br/>
        <span>For <Tag>any 3-party Products</Tag>:</span><br/>
        <span>- Back-to-back offer with advance payment required</span><br/>
        <br/>
        <span>For <Tag>Standalone Modern+ / Safeti+</Tag> <Tag>MIT Plan</Tag> <Tag>Azure PAYG</Tag> <Tag>Manage Plus</Tag> <Tag>PAYG Hosted Product</Tag>:</span><br/>
        <span>- Advance payment &/or Deposit is not applicable</span><br/>
      </>,
    }
  }

  return message;
}